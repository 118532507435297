import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function Footer() {
	const today = new Date().getFullYear();
	const uiService = useHtmlClassService();

	const { currentState } = useSelector(
		(state) => ({ currentState: state.ward }),
		shallowEqual
	);
	const layoutProps = useMemo(() => {
		return {
			footerClasses: uiService.getClasses("footer", true),
			footerContainerClasses: uiService.getClasses("footer_container", true),
		};
	}, [uiService]);

	const getPartnerText = () => {
		if (currentState.selectedLanguage == "nepali") {
			return "यो पालिका प्रोफाइल पुनर्वास नगरपालिका र युएसएआइडी साझेदारी-सङ्घीयता सहयोगको सहकार्यमा तयार पारिएको हो";
		}
		return "This municipal digital profile has been developed in collaboration with Punarbas  Municipality and USAID’s Sahjedari-Support to Federalism.";
	};
	return (
		<div
			className={`footer bg-white py-5 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
			id="kt_footer"
		>
			<div className="container">
				<div className="d-flex flex-column align-items-center">
					<h6 className="text-primary mb-3">{getPartnerText()}</h6>
					<h6 className="mx-5">
						©{" "}
						{currentState.selectedLanguage == "nepali"
							? "सर्बाधिकरण पुनर्वास नगरपालिका"
							: "All rights reserved Punarbas municipality"}
					</h6>
				</div>
				{/* <div className="row">
				<div className="col-lg-8 text-center text-lg-left d-flex align-items-center">
					</div>
				<div className="col-lg-4 text-center d-flex justify-content-end align-items-end">
					
				</div>
				</div> */}
			</div>
		</div>
	);
}
