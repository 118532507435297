import React, { Component } from "react";
import { Card } from "react-bootstrap";
import PersonCard from "../components/PersonCard";

class Representative extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}
	componentDidMount() {
		const people = [
			{
				name: "तोय प्रसाद शर्मा",
				nameEnglish: "Toya Prashad Sharma",
				designation: "नगर प्रमुख",
				designationEnglish: "Mayor",
				// email: "bistsurendra21@gmail.com",
				phone: "९८५८७३६९९९",
				phoneEnglish: "9858736999",
				address: "पुनर्वास नगरपालिका",
				addressEnglish: "Punarbas Municipality",
				image: "media/hamropalika/people/st1.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
			{
				name: "भुलिया कुमारी राना",
				nameEnglish: "Bhuliya Kumari Rana",
				designation: "नगर उप-प्रमुख ",
				designationEnglish: "Deputy Mayor",
				email: "",
				phoneEnglish: "9814692563",
				phone: "९८१४६९२५६३",
				address: "पुनर्वास नगरपालिका",
				addressEnglish: "Punarbas Municipality",
				image: "media/hamropalika/people/st2.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
		];
		this.setState({ people });
	}
	render() {
		return (
			<div className="row h-100">
				{this.state.people.map((person, index) => {
					return <PersonCard data={person} key={index} />;
				})}
			</div>
		);
	}
}

export default Representative;
