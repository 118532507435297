import React, { Component } from "react";
import PieChartCard from "../components/PieChartCard";
import PieChartProgressCard from "../components/PieChartProgressCard";
import { connect } from "react-redux";

class FarmAnimal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pieProgressData: [],
			pieData: [],
		};
	}
	// componentDidMount() {
	componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps !== this.props) {
			let pieProgressData = [
				{
					id: 1,
					data: nextProps.detail.selectedLandForAgriculture?.data.map(
						({ value }) => value
					),
					labelDesc: nextProps.detail.selectedLandForAgriculture?.data.map(
						(datum) => {
							return datum[nextProps.detail.selectedLanguage] || "";
						}
					),
					containerClass: "col-md-6",
					labels: nextProps.detail.selectedLandForAgriculture?.data.map(
						({ value }) => value
					),
					title:
						nextProps.detail.landForAgricultureList.title[
							nextProps.detail.selectedLanguage
						],
					color: "success",
					value: nextProps.detail.landForAgricultureList.percentage,
				},
				// {
				// 	id: 4,
				// 	data: [79, 80],
				// 	labelDesc: ["desc1", "desc2"],
				// 	containerClass: "col-md-6",
				// 	labels: ["data1", "data2"],
				// 	title: "मातृभाषाको आधारमा विवरण ",
				// },
				// {
				// 	id: 5,
				// 	data: [79, 80],
				// 	labelDesc: ["desc1", "desc2"],
				// 	containerClass: "col-md-6",
				// 	labels: ["data1", "data2"],
				// 	title: "मातृभाषाको आधारमा विवरण ",
				// },
				// {
				// 	id: 6,
				// 	data: [79, 80],
				// 	labelDesc: ["desc1", "desc2"],
				// 	containerClass: "col-md-6",
				// 	labels: ["data1", "data2"],
				// 	title: "मातृभाषाको आधारमा विवरण ",
				// },
				// {
				// 	id: 7,
				// 	data: [79, 80, 79, 80, 79, 80, 79, 80, 79, 80],
				// 	containerClass: "col-md-12",
				// 	labels: [
				// 		"data1",
				// 		"data2",
				// 		"data3",
				// 		"data4",
				// 		"data5",
				// 		"data6",
				// 		"data7",
				// 		"data8",
				// 		"data9",
				// 		"data10",
				// 	],
				// 	labelDesc: [
				// 		"desc1",
				// 		"desc2",
				// 		"desc3",
				// 		"desc4",
				// 		"desc5",
				// 		"desc6",
				// 		"desc7",
				// 		"desc8",
				// 		"desc9",
				// 		"desc10",
				// 	],
				// 	title: "मातृभाषाको आधारमा विवरण ",
				// },
			];
			let pieData = [
				{
					id: 1,
					data: nextProps.detail.selectedIsLiveStock?.data.map(
						({ value }) => value
					),
					labelDesc: nextProps.detail.selectedIsLiveStock?.data.map((datum) => {
						return datum[nextProps.detail.selectedLanguage] || "";
					}),
					containerClass: "col-md-6",
					labels: nextProps.detail.selectedIsLiveStock?.data.map(
						({ value }) => value
					),
					title:
						nextProps.detail.isLiveStockList.title[
							nextProps.detail.selectedLanguage
						],
					value: nextProps.detail.isLiveStockList.percentage,
				},
				{
					id: 2,
					data: nextProps.detail.selectedAnimalCount?.data.map(
						({ value }) => value
					),
					labelDesc: nextProps.detail.selectedAnimalCount?.data.map((datum) => {
						return datum[nextProps.detail.selectedLanguage] || "";
					}),
					containerClass: "col-md-6",
					labels: nextProps.detail.selectedAnimalCount?.data.map(
						({ value }) => value
					),
					title:
						nextProps.detail.animalCountList.title[
							nextProps.detail.selectedLanguage
						],
				},
			];
			this.setState({ pieProgressData, pieData });
		}
	}
	render() {
		return (
			<>
				<div className="row">
					{this.state.pieProgressData &&
						this.state.pieProgressData.map((data, index) => {
							return (
								<PieChartProgressCard
									data={data}
									key={index}
									section="farm_animal_pie"
								/>
							);
						})}
					{this.state.pieData &&
						this.state.pieData.map((data, index) => {
							return (
								<PieChartCard data={data} key={index} section="farm_animal" />
							);
						})}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	detail: state.ward,
});
export default connect(mapStateToProps)(FarmAnimal);
