import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Card } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";

const PersonCard = (props) => {
	const { selectedLanguage } = useSelector(
		(state) => ({ selectedLanguage: state.ward.selectedLanguage }),
		shallowEqual
	);
	return (
		<div className={`mb-4 ${props.data.containerClass}`}>
			<Card className="h-100">
				<Card.Body>
					<div className="d-flex  justify-content-center align-items-center flex-column">
						<img
							src={toAbsoluteUrl(props.data.image)}
							alt=""
							className=" mb-4 representative"
						/>
						<div className="text-center">
							<h6 className="mb-3 ">
								{selectedLanguage == "nepali"
									? props.data.name
									: props.data.nameEnglish}
							</h6>
							<h6 className="mb-3">
								{selectedLanguage == "nepali"
									? props.data.designation
									: props.data.designationEnglish}
							</h6>
						</div>
					</div>
					<div className="person-details">
						<div className="row">
							<div className="col-4">
								<h6>{selectedLanguage == "nepali" ? "ईमेल:" : "Email:"}</h6>
							</div>
							<div className="col-8">
								<h6
									style={{
										fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
									}}
								>
									{props.data.email}
								</h6>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<h6>{selectedLanguage == "nepali" ? "फोन:" : "Phone:"}</h6>
							</div>
							<div className="col-8">
								<h6>
									{selectedLanguage == "nepali"
										? props.data.phone
										: props.data.phoneEnglish}
								</h6>
							</div>
						</div>
						{/* <div className="row">
							<div className="col-4">
								<h6>
									{selectedLanguage == "nepali" ? "ठेगाना :" : "Address:"}
								</h6>
							</div>
							<div className="col-8">
								<h6>
									{selectedLanguage == "nepali"
										? props.data.address
										: props.data.addressEnglish}
								</h6>
							</div>
						</div> */}
					</div>
				</Card.Body>
			</Card>
		</div>
	);
};

export default PersonCard;
