import React, { Component } from "react";
import PersonCard from "../components/PersonCard";

class Staff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}
	componentDidMount() {
		const people = [
			{
				name: "गणेश सिंह ऐरी",
				nameEnglish: "Ganesh Singh Aeri",
				designation: "प्रमुख प्रशासकीय अधिकृत",
				designationEnglish: "Chief Administrative Officer",
				email: "gsairee28@gmail.com",
				phoneEnglish: "9858726111",
				phone: "९८५८७२६१११",
				address: "पुनर्वास नगरपालिका",
				addressEnglish: "Punarbas Municipality",
				image: "media/hamropalika/people/st3.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
			{
				name: "धौल राम सार्की",
				nameEnglish: "Dhaul Ram Saarki",
				designation: "गुनासो तथा सूचना अधिकारी",
				designationEnglish: "Information Officer",
				email: "dhaulram2085@gmail.com",
				phoneEnglish: "9858777043",
				phone: "९८५८७७७०४३",
				address: "पुनर्वास नगरपालिका",
				addressEnglish: "Punarbas Municipality",
				image: "media/hamropalika/people/st4.png",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
		];
		this.setState({ people });
	}
	render() {
		return (
			<div className="row h-100">
				{this.state.people.map((person, index) => {
					return <PersonCard data={person} key={index} />;
				})}
			</div>
		);
	}
}

export default Staff;
