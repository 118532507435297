import React, { Component } from "react";
import { connect } from "react-redux";
import BarGraphCard from "../components/BarGraphCard";
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";

class StatsGraphChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barData: [],
		};
	}
	componentWillReceiveProps(nextProps) {
		let femaleTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "महिला" : "Female";
		let maleTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "पुरुष" : "Male";
		let thirdTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "अन्य" : "Other";
		if (
			nextProps.detail.selectedEthnics !== this.props.detail.selectedEthnics ||
			this.props.detail.selectedLanguage !== nextProps.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedEthnics?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedEthnics?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedEthnics?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedEthnics?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let ethnicsData = {
				id: 1,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.ethnicsList.title[nextProps.detail.selectedLanguage],
				categories: labelData,
				detail: "Ethnics",
				xaxis: "Castes",
				xaxis_nepali: "जातजाति",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 1);
				if (!prevWardData) {
					barData.push(ethnicsData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = ethnicsData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedOccupation !==
				this.props.detail.selectedOccupation ||
			this.props.detail.selectedLanguage !== nextProps.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedOccupation?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedOccupation?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedOccupation?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedOccupation?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let occupationData = {
				id: 2,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.occupationList.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "Occupation",
				xaxis: "Professions",
				xaxis_nepali: "पेशा",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 2);
				if (!prevWardData) {
					barData.push(occupationData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = occupationData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedAge !== this.props.detail.selectedAge ||
			this.props.detail.selectedLanguage !== nextProps.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedAge?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedAge?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedAge?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedAge?.data.third?.map((datum) => {
				return datum[nextProps.detail.selectedLanguage];
			});
			// console.log("social allowance", nextProps.detail.socialAllowanceList.title[nextProps.detail.selectedLanguage])
			let ageData = {
				id: 3,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.ageList.title[nextProps.detail.selectedLanguage],
				categories: labelData,
				detail: "Age",
				xaxis: "Age group",
				xaxis_nepali: "उमेर",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 3);
				if (!prevWardData) {
					barData.push(ageData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = ageData;
				}
				return {
					barData,
				};
			});
		}
	}
	componentDidMount() {
		this.props.dispatch(actions.fetchOccupation());
		this.props.dispatch(actions.fetchEthnics());
		this.props.dispatch(actions.fetchAge());
	}
	render() {
		return (
			<div className="row">
				{this.state.barData?.map((datum, index) => {
					return (
						<BarGraphCard
							key={index}
							datum={datum}
							section="population_bar_graph"
						/>
					);
				})}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	detail: state.ward,
});
export default connect(mapStateToProps)(StatsGraphChart);
