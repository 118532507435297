import React, { Component } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import L from "leaflet";
import { MapContainer, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
import hash from "object-hash";
import Axios from "../../redux/axiosHandlerGeo";
import GeoJsonLayer from "./GeoJsonLayer";
import kanchanpur from "../../geojson/kanchanpur.json";
import { infastructure } from "../../variable/global";
// import kanchanpur from "../../geojson/bedkot.json";
import "leaflet/dist/leaflet.css";
import { connect } from "react-redux";

class EmergencyContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
		};
	}
	componentDidMount() {
		let sections = [
			{
				title: "न्यायिक समिति संयोजक",
				numbers: [
					{
						title: "शारदा शर्मा विष्ट",
						englishTitle: "Saarda sharma bista",
						value: "९८४८७५२५१३",
						englishValue: "9848752513",
					},
					{
						title: "मानबहादुर तामाङ",
						englishTitle: "Manbahadhur tamang",
						value: "९८०४६८६०७५",
						englishValue: "9804686075",
					},
					{
						title: "देउशरा बीक",
						englishTitle: "Deusara bik",
						value: "९८४८७४५५०४",
						englishValue: "9848745504",
					},
				],
			},
			{
				title: "एम्बुलेन्स सेवा",
				englishTitle: "Ambulance Service Provider",
				numbers: [
					{
						title: "खडक खड्का",
						englishTitle: "Khadka Khadkaa",
						value: "९८६२७१५९९९",
						englishValue: "9862715999",
					},
				],
			},
			{
				title: "दमकल सेवा",
				englishTitle: "Fire Brigade Service Provider",
				numbers: [
					{
						title: "तुलसी तिमल्सिना",
						englishTitle: "Tulsi Timalsina",
						value: "९८१२७२५१२१",
						englishValue: "9812725121",
					},
					{
						title: "दल बहादुर गुरुङ",
						englishTitle: "dal bahadhur gurung",
						value: "९८१८४९२७५१",
						englishValue: "9818492751",
					},
				],
			},
			{
				title: "अन्य सेवा",
				englishTitle: "Other Service Provider",
				numbers: [
					{
						title: "प्रहरी सम्पर्क कार्यालय (पैसा नलाग्ने फोन नं)",
						englishTitle: "Police Office (toll free number)",
						value: "०९९-५६०००२, ९८५८७९०४०५",
						englishValue: "099-560002, 9858790405",
					},
					{
						title: "बाल हेल्पलाइन (पैसा नलाग्ने फोन नं)",
						englishTitle: "baal helpline (toll free number)",
						value: "१०९८",
						englishValue: "1098",
					},
					{
						title: "मानसिक स्वास्थ्य सम्बन्धित क्लिनिकल सेवा",
						englishTitle: "Mental health related clinc service",
						value: "१६६६०-०१-२२२३",
						englishValue: "16660-01-2223",
					},
					{
						title: "रेडक्रस",
						englishTitle: "Red cross",
						value: "१६६६०-०१-२२२३",
						englishValue: "16660-01-2223",
					},
					{
						title: "कोभिड-१९ सम्बन्धित प्रश्नहरु",
						englishTitle: "Covid-19 related",
						value: "१११६ ,१११३",
						englishValue: "1116, 1113",
					},
					{
						title: "महिला हिंसाविरुद्ध घटनाको उजुरी",
						englishTitle: "Women's violence incident",
						value: "११४५",
						englishValue: "1145",
					},
					{
						title:
							"कोभिड-१९ contact person and public health officer for inquiries",
						englishTitle: "Covid-19 ",
						value: "९८६०८६१६९७",
						englishValue: "9860861697",
					},
				],
			},
		];
		this.setState({ sections });
	}
	renderContacts() {
		return (
			<>
				{this.state.sections.map((section, secIndex) => {
					return (
						<div key={secIndex}>
							{section.title ? (
								<>
									<h3 className="font-weight-bolder mb-4 mt-4">
										{this.props.selectedLanguage == "nepali"
											? section.title
											: section.englishTitle}
									</h3>
								</>
							) : null}
							<div className="row">
								{section.numbers.map((number, numIndex) => {
									return (
										<div
											className="col-lg-6 d-flex flex-wrap mb-4"
											key={numIndex}
										>
											<h5 className="font-weight-bold mr-2">
												{this.props.selectedLanguage == "nepali"
													? number.title
													: number.englishTitle}
												:{" "}
											</h5>
											<h5 className="text-primary font-weight-bold">
												{this.props.selectedLanguage == "nepali"
													? number.value
													: number.englishValue}
											</h5>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</>
		);
	}
	render() {
		return (
			<Modal
				{...this.props}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				closeButton
			>
				<Modal.Body className="p-md-15">
					<div className="d-flex justify-content-between mb-6">
						<h1 className="font-weight-bolder">
							<i className="icon-emergency text-primary display-4"></i>{" "}
							{this.props.selectedLanguage == "nepali"
								? "आपतकालीन सम्पर्क"
								: "Emergency Contact"}
						</h1>
						<i className="fa fa-times" onClick={this.props.onHide}></i>
					</div>
					{this.renderContacts()}
				</Modal.Body>
			</Modal>
		);
	}
}
class MunicipalityDescription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalShow: false,
			boundData: [],
			wholeGeojson: [],
			location: {
				lat: 28.96725102178788,
				lng: 80.18087073179544,
			},
			zoom: 11,
			checkbox: {},
			wholeGeoJSON: {},
			showGeoJSON: [],
			sectionTitle: "",
		};
	}
	// 	this.getMunicpalId = this.getMunicpalId.bind(this);
	// }

	// isGeoJsonAlreadyFetched = (field) => {
	// 	const { wholeGeoJSON } = this.state;
	// 	return Boolean(wholeGeoJSON[field]);
	// };

	// fetchGeoJSON = (changedData) => {
	// 	const body = {
	// 		industries: "institute/industries",
	// 		cultural_heritage: "institute/cultural_heritage",
	// 		gov_office: "institute/gov_office",
	// 		forest: "institute/forest",
	// 		offices: "institute/offices",
	// 		infrastructure: "institute/infrastructure",
	// 		economic: "institute/economic",
	// 		education: "institute/education",
	// 		security: "institute/security",
	// 		palika_related_office: "institute/palika_related_office",
	// 		public_places: "institute/public_places",
	// 		health: "institute/health",
	// 	};

	// 	return new Promise((resolve, reject) => {
	// 		Axios.get(`/api/geojson/${body[changedData]}`)
	// 			.then((result) => {
	// 				const wholeGeoJSON = this.state.wholeGeoJSON;
	// 				this.setState(
	// 					{
	// 						wholeGeoJSON: {
	// 							...wholeGeoJSON,
	// 							[changedData]: result.data,
	// 						},
	// 					},
	// 					() => resolve()
	// 				);
	// 			})
	// 			.catch((err) => reject());
	// 	});
	// };

	// arrangeAllOption = async () => {
	// 	const { checkbox } = this.state;

	// 	const showGeoJSON = [];
	// 	let dataToCheck = [];

	// 	dataToCheck = dataToCheck.concat(infastructure);
	// 	const data = dataToCheck.map(async (each, i) => {
	// 		if (checkbox[each.value]) {
	// 			if (!this.isGeoJsonAlreadyFetched(each.value))
	// 				await this.fetchGeoJSON(each.value);

	// 			showGeoJSON.push(this.state.wholeGeoJSON[each.value]);
	// 		}
	// 	});
	// 	// wait until all promises resolve
	// 	await Promise.all(data);

	// 	this.setState({ showGeoJSON });
	// };

	// handleChange = (e) => {
	// 	e.persist();
	// 	this.setState(
	// 		({ checkbox }) => ({
	// 			checkbox: {
	// 				...checkbox,
	// 				[e.target.name]: e.target.checked,
	// 			},
	// 		}),
	// 		this.arrangeAllOption
	// 	);
	// };

	// style(feature) {
	// 	let skin = "blue";
	// 	if (feature.properties.FIRST_GaPa === "Bhimdatta") {
	// 		skin = "green";
	// 	}
	// 	return {
	// 		fillColor: skin,
	// 		weight: 1,
	// 		opacity: 1,
	// 		color: "black",
	// 		fillOpacity: 0.3,
	// 	};
	// }

	componentDidMount() {
		const boundData = [
			{
				key: "पुर्व",
				keyEnglish: "East",
				value: "भारतको दुधवा नेशनल पार्क",
				valueEnglish: "Dhuwa national park, india",
				class: "col-md-12",
			},
			{
				key: "उत्तर",
				keyEnglish: "North",
				value: "लालझाडी गाउँपालिका",
				valueEnglish: "Laljhadi rural municipality",
				class: "col-md-12",
			},
			{
				key: "पश्चिम",
				keyEnglish: "West",
				value: "बेलौरी नगरपालिका",
				valueEnglish: "Belauri Municipality",
				class: "col-md-12",
			},
			{
				key: "दक्षिण",
				keyEnglish: "South",
				value: "भारतको दुधवा नेशनल पार्क",
				valueEnglish: "Dhuwa national park, india",
				class: "col-md-12",
			},
			{
				key: "क्षेत्रफल ",
				keyEnglish: "Area",
				value: "१०३.७१ वर्ग कि.मि.",
				valueEnglish: "103.71 sq. km",
				class: "col-md-12",
			},
			{
				key: "वडा संख्या",
				keyEnglish: "Ward",
				value: "११",
				valueEnglish: "11",
				class: "col-md-12",
			},
			{
				key: "अक्षांश तथा देशान्तर",
				keyEnglish: "lattitude and longitude",
				value: "२८.६४५०५४° N, ८०.४६०४६४° W",
				valueEnglish: "28.645054° N, 80.460464° W",
				class: "col-12",
			},
			// {
			// 	key: "मुख्य सहरी क्षेत्र",
			// 	value: "",
			// 	class: "col-12",
			// },
		];
		this.setState({ ...this.state, boundData });
	}
	getMunicipalBounds() {
		let modalClose = () => this.setState({ modalShow: false });
		return (
			<div className="col-lg-5 pb-5 d-flex">
				<Card>
					<Card.Body>
						<div className="d-flex pb-8">
							<SVG
								className="primary-icon"
								src={toAbsoluteUrl("media/hamropalika/svg/map_marker.svg")}
								alt=""
							/>
							<h2 className="font-weight-black pl-3 pt-6">
								{this.props.selectedLanguage == "nepali"
									? "सिमाना"
									: "Boundaries"}
							</h2>
						</div>
						<div className="row">
							{this.state.boundData.map((data, index) => {
								return (
									<div className={`pb-3 ${data.class}`} key={index}>
										<div className="row">
											<div className="col-4">
												<h5 className="pr-2">
													{this.props.selectedLanguage == "nepali"
														? data.key
														: data.keyEnglish}
													:
												</h5>
											</div>
											<div className="col-8">
												<h5 className="text-primary">
													{this.props.selectedLanguage == "nepali"
														? data.value
														: data.valueEnglish}
												</h5>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<div className="float-right ">
							<Button
								className="px-10 emergency-button"
								variant="light-danger"
								size="lg"
								onClick={() => this.setState({ modalShow: true })}
							>
								{" "}
								<i className="icon-emergency"></i>{" "}
								{this.props.selectedLanguage == "nepali"
									? "आपतकालीन सम्पर्क"
									: "Emergency Contact"}
							</Button>

							<EmergencyContactModal
								selectedLanguage={this.props.selectedLanguage}
								show={this.state.modalShow}
								onHide={modalClose}
							/>
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
	getMunicpalId() {
		return (
			<div className="col-lg-7 pb-5 d-flex ">
				<Card>
					<Card.Body>
						<div className="d-flex pb-8">
							<SVG
								className="primary-icon"
								src={toAbsoluteUrl("media/hamropalika/svg/municipality_id.svg")}
								alt=""
							/>
							<h2 className="font-weight-black pl-3 pt-6">
								{this.props.selectedLanguage == "nepali"
									? "नगरपालिका अबस्थित  नक्शा"
									: "Municipality Location Map"}
							</h2>
						</div>

						<img
							className="w-100"
							src={toAbsoluteUrl("/media/hamropalika/static-map.png")}
							alt=""
						/>
					</Card.Body>
				</Card>
			</div>
		);
	}
	render() {
		return (
			<>
				<div className="row">
					{this.getMunicpalId()}
					{this.getMunicipalBounds()}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(MunicipalityDescription);
