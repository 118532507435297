export const gaupalikaNepali = "पुनर्वास";
export const gaupalikaEnglish = "Punarbas";
export const ruralMunicipalNepali = "नगरपालिका";
export const ruralMunicipalEnglish = "Municipality";
export const gaupalika = { nepali: gaupalikaNepali, english: gaupalikaEnglish };

export const infastructure = [
	{
		icon: "book",
		english: "Educational Institution",
		nepali: "शैक्षिक सँस्था",
		value: "school",
	},
	{
		icon: "government",
		english: "Ward Office",
		nepali: "वडा कार्यालय",
		value: "ward_office",
	},
	{
		icon: "hospital",
		english: "Health Institution",
		nepali: "स्वास्थ केन्द्र",
		value: "health",
	},
	{
		icon: "infrastructure",
		english: "Temple",
		nepali: "मन्दिर",
		value: "Mandir",
	},
	{
		icon: "infrastructure",
		english: "Gumba",
		nepali: "गुम्बा",
		value: "Gumba",
	},
	{
		icon: "infrastructure",
		english: "Church",
		nepali: "चर्च",
		value: "Church",
	},
	{
		icon: "infrastructure",
		english: "Furniture",
		nepali: "फर्निचर",
		value: "Furniture",
	},
	{
		icon: "park",
		english: "Lake",
		nepali: "ताल",
		value: "Tal",
	},
	{
		icon: "park",
		english: "Park",
		nepali: "पार्क",
		value: "park",
	},
	{
		icon: "government",
		english: "Government Offices",
		nepali: "सरकारी कार्यालयहरु",
		value: "other_gov_office",
	},
	{
		icon: "building",
		english: "Electricity Authority",
		nepali: "विद्युत प्राधिकरण",
		value: "electricity_authority",
	},
	{
		icon: "building",
		english: "Khanepani",
		nepali: "खानेपानी",
		value: "Khanepani",
	},
	{
		icon: "building",
		english: "Graveyard",
		nepali: "मसानघात",
		value: "Masanghat",
	},
	{
		icon: "factory",
		english: "Industries",
		nepali: "उधोग तथा कलकारखाना",
		value: "Udhog",
	},
	{
		icon: "factory",
		english: "Bhatta",
		nepali: "भट्टा",
		value: "Bhatta",
	},
	{
		icon: "police-station",
		english: "Security body Offices",
		nepali: "सुरक्षा निकाय सम्बन्धी",
		value: "police",
	},
	{
		icon: "nature",
		english: "Red Cross",
		nepali: "रेड क्रस",
		value: "red_cross",
	},
	{
		icon: "book",
		value: "palika_related_office",
		english: "Palika Related Offices",
		nepali: "गाउँ/नगर केन्द्र र वडा कार्यालय",
	},
];

export const ward = [
	{
		backgroundColor: "#f44336",
		key: 1,
		value: 1,
		ward: "वडा न. १",
		english: "ward 1",
		nepali: "वडा न. १",
	},
	{
		key: 2,
		value: 2,
		backgroundColor: "#ff5722",
		ward: "वडा न. २ ",
		english: "ward 2",
		nepali: "वडा न. २ ",
	},
	{
		key: 3,
		value: 3,
		backgroundColor: "#673ab7",
		ward: "वडा न. ३",
		english: "ward 3",
		nepali: "वडा न. ३",
	},
	{
		key: 4,
		value: 4,
		backgroundColor: "#e91e63",
		ward: "वडा न. ४",
		english: "ward 4",
		nepali: "वडा न. ४",
	},
	{
		key: 5,
		value: 5,
		backgroundColor: "#9c27b0",
		ward: "वडा न. ५",
		english: "ward 5",
		nepali: "वडा न. ५",
	},
	{
		key: 6,
		value: 6,
		backgroundColor: "#9c27b0",
		ward: "वडा न. ६",
		english: "ward 6",
		nepali: "वडा न. ६",
	},
	{
		key: 7,
		value: 7,
		backgroundColor: "#9c27b0",
		ward: "वडा न. ७",
		english: "ward 7",
		nepali: "वडा न. ७",
	},
	{
		backgroundColor: "#f44336",
		key: 8,
		value: 8,
		ward: "वडा न. ८",
		english: "ward 8",
		nepali: "वडा न. ८",
	},
	{
		key: 9,
		value: 9,
		backgroundColor: "#ff5722",
		ward: "वडा न. ९ ",
		english: "ward 9",
		nepali: "वडा न. ९ ",
	},
	{
		key: 10,
		value: 10,
		backgroundColor: "#673ab7",
		ward: "वडा न. १०",
		english: "ward 10",
		nepali: "वडा न. १०",
	},
	{
		key: 11,
		value: 11,
		backgroundColor: "#e91e63",
		ward: "वडा न. ११",
		english: "ward 11",
		nepali: "वडा न. ११",
	},
];
