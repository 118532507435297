import axiosHandler from "../../../../redux/axiosHandler";

const POPULATION_COUNT = "populationCount";
const HOUSE_OWNER_GENDER = "houseOwnerGender";
const ABROAD = "abroad";
const OCCUPATION = "occupation";
const EDUCATION = "education";
const DISTANCE_TO_PRIMARY = "distanceToPrimarySchool";
const DISTANCE_TO_SECONDARY = "distanceToSecondarySchool";
const ETHNICS = "ethnics";
const LIVINGTYPE = "livingtype";
const RELIGION = "religion";
const MOTHER_TONGUE = "mothertongue";
const IS_SCHOOL_GOING = "isSchoolGoing";
const MARITAL = "martial";
const DASHBOARD_PERCENTAGE = "dashboardPercentage";
const BANK_ACCOUNT_YES_NO = "bankAccountYesNo";
const IS_LOAN = "isLoan";
const IS_RENT = "isRent";
const IS_SKILL = "isSkillTraining";
const LAND_FOR_AGRICULTURE = "landForAgriYesNo";
const IS_LIVESTOCK = "isLiveStock";
const ANIMAL_COUNT = "animalCount";
const HOUSE_USED_LIVING = "houseUsedLiving";
const HOUSE_MAP_PASS = "houseMapPass";
const ANNUAL_INCOME = "annualIncome";
const ANNUAL_EXPENSE = "annualExpense";
const HEALTH = "health";
const UNHEALTHY = "unHealthy";
const DISABLE = "disable";
const DISABLE_CARD_YESNO = "disableCardYesNo";
const EDUCATION_SECTOR = "educationSector";

const WATER_RESOURCE = "wateResource";
const COOKING_SOURCE = "cookingSource";
const ELECTRIC_SOURCE = "electricSource";
const TOILET = "toilet";
const GARBAGE = "garbage";
const STOVE_DETAIL = "stoveDetail";
const TOTAL_STOREY = "totalStorey";
const ROOF_TYPE = "roofType";
const INSTITUTUION_COUNT = "institutionCount";
const AGE = "age";
const SOCIAL_ALLOWANCE = "socialAllowance";
const ANNUAL_AGRI_INCOME = "annualAgriIncome";
const ANNUAL_AGRI_EXPENSE = "annualAgriExpense";
const SKILL = "skill";
const DISABLE_REASON = "disableReason";
const HOUSE_TYPE = "houseType";
const FOOD_FOR_MONTH = "foodformonth";
// READ
// Server should return filtered specifications by productId
// Janasankhya section API calls
export function getAnnualIncome() {
	return axiosHandler.get(`${ANNUAL_INCOME}`);
}
export function getAnnualExpense() {
	return axiosHandler.get(`${ANNUAL_EXPENSE}`);
}
export function getPopulationCount() {
	return axiosHandler.get(`${POPULATION_COUNT}`);
}
export function getHouseOwnerGender() {
	return axiosHandler.get(`${HOUSE_OWNER_GENDER}`);
}
export function getAbroad() {
	return axiosHandler.get(`${ABROAD}`);
}
export function getOccupation() {
	return axiosHandler.get(`${OCCUPATION}`);
}
export function getEducation() {
	return axiosHandler.get(`${EDUCATION}`);
}
export function getDistanceToPrimary() {
	return axiosHandler.get(`${DISTANCE_TO_PRIMARY}`);
}
export function getDistanceToSecondary() {
	return axiosHandler.get(`${DISTANCE_TO_SECONDARY}`);
}
export function getEthnics() {
	return axiosHandler.get(`${ETHNICS}`);
}
export function getLivingType() {
	return axiosHandler.get(`${LIVINGTYPE}`);
}
export function getReligion() {
	return axiosHandler.get(`${RELIGION}`);
}
export function getMotherTongue() {
	return axiosHandler.get(`${MOTHER_TONGUE}`);
}
export function getIsSchoolGoing() {
	return axiosHandler.get(`${IS_SCHOOL_GOING}`);
}
export function getMarital() {
	return axiosHandler.get(`${MARITAL}`);
}
export function getDashboardPercentage() {
	return axiosHandler.get(`${DASHBOARD_PERCENTAGE}`);
}
//swastha section API calls
export function getHealth() {
	return axiosHandler.get(`${HEALTH}`);
}
export function getUnhealthy() {
	return axiosHandler.get(`${UNHEALTHY}`);
}
export function getDisable() {
	return axiosHandler.get(`${DISABLE}`);
}
export function getDisableCardYesNo() {
	return axiosHandler.get(`${DISABLE_CARD_YESNO}`);
}
// saichik sanstha
export function getEducationSector() {
	return axiosHandler.get(`${EDUCATION_SECTOR}`);
}

//awas taatha bhawan
export function getStoveDetail() {
	return axiosHandler.get(`${STOVE_DETAIL}`);
}
export function getTotalStorey() {
	return axiosHandler.get(`${TOTAL_STOREY}`);
}
export function getRoofType() {
	return axiosHandler.get(`${ROOF_TYPE}`);
}

// Aarthik section API calls
export function getBankAccountYesNo() {
	return axiosHandler.get(`${BANK_ACCOUNT_YES_NO}`);
}
export function getIsLoan() {
	return axiosHandler.get(`${IS_LOAN}`);
}
export function getIsRent() {
	return axiosHandler.get(`${IS_RENT}`);
}
export function getIsSkill() {
	return axiosHandler.get(`${IS_SKILL}`);
}
export function getLandForAgriculture() {
	return axiosHandler.get(`${LAND_FOR_AGRICULTURE}`);
}
export function getIsLiveStock() {
	return axiosHandler.get(`${IS_LIVESTOCK}`);
}
export function getAnimalCount() {
	return axiosHandler.get(`${ANIMAL_COUNT}`);
}
export function getHouseUsedLiving() {
	return axiosHandler.get(`${HOUSE_USED_LIVING}`);
}
export function getHouseMapPass() {
	return axiosHandler.get(`${HOUSE_MAP_PASS}`);
}
// Khanepani section API calls

export function getWaterResource() {
	return axiosHandler.get(`${WATER_RESOURCE}`);
}
export function getCookingSource() {
	return axiosHandler.get(`${COOKING_SOURCE}`);
}
export function getElectricSource() {
	return axiosHandler.get(`${ELECTRIC_SOURCE}`);
}
export function getToilet() {
	return axiosHandler.get(`${TOILET}`);
}
export function getGarbage() {
	return axiosHandler.get(`${GARBAGE}`);
}
export function getInstitutionCount() {
	return axiosHandler.get(`${INSTITUTUION_COUNT}`);
}

// Remaining api calls
export function getAge() {
	return axiosHandler.get(`${AGE}`);
}
export function getSocialAllowance() {
	return axiosHandler.get(`${SOCIAL_ALLOWANCE}`);
}
export function getAnnualAgriIncome() {
	return axiosHandler.get(`${ANNUAL_AGRI_INCOME}`);
}
export function getAnnualAgriExpense() {
	return axiosHandler.get(`${ANNUAL_AGRI_EXPENSE}`);
}
export function getSkill() {
	return axiosHandler.get(`${SKILL}`);
}
export function getDisableReason() {
	return axiosHandler.get(`${DISABLE_REASON}`);
}
export function getHouseType() {
	return axiosHandler.get(`${HOUSE_TYPE}`);
}
export function getFoodForMonth() {
	return axiosHandler.get(`${FOOD_FOR_MONTH}`);
}
